<template>
  <div class="container my-5 py-5">
    <h2 class="headings">Our weekly pick</h2>
    <p class="mb-5 mt-3">
      Our carefully crafted and curated weekly pick is a fan favorite.
    </p>
    <div class="grid">
      <div v-for="(item, index) in data" :key="index">
        <article-item :article="item"></article-item>
      </div>
    </div>
  </div>
</template>
<script>
import ArticleItem from "../../components/Article.vue";
export default {
  components: {
    ArticleItem,
  },
  data() {
    return {
      data: [
        {
          title: "Ying-Yang and a little tang",
          description:
            "It is a long established fact that a reader will be distracted.",
          image: "/images/articles/item1.png",
          link: "",
          category: "Painting",
        },
        {
          title: "A thousand words",
          description:
            "It is a long established fact that a reader will be distracted.",
          image: "/images/articles/item2.png",
          link: "",
          category: "Photography",
        },
        {
          title: "faces of metal",
          description:
            "It is a long established fact that a reader will be distracted.",
          category: "Sculpture",
          image: "/images/articles/item3.png",
          link: "",
        },
        {
          title: "Teething tees",
          description:
            "It is a long established fact that a reader will be distracted.",
          category: "Accessories",
          image: "/images/articles/item4.png",
          link: "",
        },
      ],
    };
  },
};
</script>
