<template>
  <div>
    <Section1 />
    <Section2 />
    <Section3 />
    <Section4 />
    <Section5 />
  </div>
</template>

<script>
import Section1 from "../../containers/home/Section1.vue";
import Section2 from "../../containers/home/Section2.vue";
import Section3 from "../../containers/home/Section3.vue";
import Section4 from "../../containers/home/Section4.vue";
import Section5 from "../../containers/home/Section5.vue";
export default {
  components: {
    Section1,
    Section2,
    Section3,
    Section4,
    Section5,
  },
};
</script>
