<template>
  <div class="article mb-4">
    <span class="tag">{{ article.category }}</span>
    <img :src="article.image" alt="image" />
    <h3 class="headings mt-3">{{ article.title }}</h3>
    <p>{{ article.description }}</p>
    <a class="pb-1 link" :href="article.link"
      ><span class="mr-2">See details</span>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.5 8H13.5"
          stroke="#6F4C5B"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9 3.5L13.5 8L9 12.5"
          stroke="#6F4C5B"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </a>
  </div>
</template>
<script>
export default {
  props: {
    article: Object,
  },
};
</script>
<style lang="scss">
.article {
  position: relative;
  img {
    height: 290x;
    object-fit: cover;
  }

  .tag {
    position: absolute;
    right: 10px;
    top: 5%;
    background: #f5e8c7;
    color: #27272a;
    font-size: 12px;
    font-weight: 700;
    padding: 0.45rem;
  }
}
</style>
