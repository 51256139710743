<template>
  <div class="product mb-4">
    <img :src="product.image" alt="image" />
    <h3 class="headings mt-3">{{ product.title }}</h3>
    <p class="mb-1">{{ product.description }}</p>
    <p>{{ product.price }}</p>
    <button class="btn btn-full" :href="product.link">
      <span class="mr-2">Buy now</span>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.5 8H13.5"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9 3.5L13.5 8L9 12.5"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
  </div>
</template>
<script>
export default {
  props: {
    product: Object,
  },
};
</script>
<style lang="scss">
.product {
  position: relative;
  img {
    height: 290x;
    object-fit: cover;
  }

  .headings {
    font-size: 1.05rem;
  }

  .btn-full {
    border-radius: 5px;
  }
}
</style>
