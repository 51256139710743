<template>
  <div class="container my-5 py-5">
    <h2 class="headings">Quick picks</h2>
    <p class="mb-5 mt-3">A quick glance at our collections</p>
    <div class="product-grid">
      <div v-for="(item, index) in data" :key="index">
        <product :product="item"></product>
      </div>
    </div>
  </div>
</template>
<script>
import Product from "../../components/Product.vue";
export default {
  components: {
    Product,
  },
  data() {
    return {
      data: [
        {
          title: "Ying-Yang and a little tang",
          description: "Neco Naco",
          image: "/images/articles/item1.png",
          price: "$14,500,000",
          category: "Painting",
        },
        {
          title: "A thousand words",
          description: "Neco Naco",
          image: "/images/articles/item2.png",
          price: "$14,500,000",
          category: "Photography",
        },
        {
          title: "faces of metal",
          description: "Neco Naco",
          category: "Sculpture",
          image: "/images/articles/item3.png",
          price: "$14,500,000",
        },
        {
          title: "Teething tees",
          description: "Neco Naco",
          category: "Accessories",
          image: "/images/articles/item4.png",
          price: "$14,500,000",
        },
        {
          title: "Ying-Yang and a little tang",
          description: "Neco Naco",
          image: "/images/articles/item1.png",
          price: "$14,500,000",
          category: "Painting",
        },
      ],
    };
  },
};
</script>
<style lang="scss">
.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-auto-rows: auto;
  grid-gap: 1rem;
}
</style>
