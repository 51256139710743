<template>
  <div class="art-section py-5">
    <div class="container my-5">
      <h2 class="headings">Find art you love</h2>
      <p class="mb-5 mt-3">Discover African art you love with just a click</p>
      <div class="row">
        <div class="col-lg-6 mb-lg-0 mb-5">
          <img src="/images/articles/item1.png" alt="image" />
          <h3 class="headings mt-4">Curators Favourite</h3>
          <p class="my-4">
            Take a trip through our curator’s art faves and see if the shoe fits
          </p>
          <a class="pb-1 link" href=""
            ><span class="mr-2">See details</span>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.5 8H13.5"
                stroke="#6F4C5B"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9 3.5L13.5 8L9 12.5"
                stroke="#6F4C5B"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </a>
        </div>
        <div class="col-lg-6">
          <img src="/images/articles/item2.png" alt="image" />
          <h3 class="headings mt-4">Diaspora Bestsellers</h3>
          <p class="my-4">
            Take a trip through our curator’s art faves and see if the shoe fits
          </p>
          <a class="pb-1 link" href=""
            ><span class="mr-2">See details</span>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.5 8H13.5"
                stroke="#6F4C5B"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9 3.5L13.5 8L9 12.5"
                stroke="#6F4C5B"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="scss">
.art-section {
  background: #f5e8c7;

  .row p {
    width: 60%;
  }

  img {
    height: 590px;
    object-fit: cover;
  }
}

@media (max-width: 1024px) {
  .art-section .row p {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .art-section .row p {
    width: 100%;
  }
}
</style>
