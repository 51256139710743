<template>
  <div class="section5 py-5">
    <div class="container my-5">
      <div class="row">
        <div class="col-lg-7">
          <ImageCarousel />
        </div>

        <div class="col-lg-5">
          <div class="d-flex align-items-center h-100">
            <div class="pl-xxl-4 pl-lg-3 pl-0">
              <button class="btn py-2 btn-full text-small">Review</button>
              <h2 class="headings my-4">Black don’t crack</h2>
              <p class="sub-title font-medium">
                Channeling the spirits of our fathers through art.
              </p>
              <p class="my-4">
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look even
                slightly believable.
              </p>
              <a class="pb-1 link" href=""
                ><span class="mr-2">See details</span>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.5 8H13.5"
                    stroke="#6F4C5B"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9 3.5L13.5 8L9 12.5"
                    stroke="#6F4C5B"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ImageCarousel from "../../components/ImageCarousel.vue";
export default {
  components: {
    ImageCarousel,
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss">
.section5 {
  background: #f5e8c7;

  .sub-title {
    color: #6f4c5b;
  }

  img {
    height: 590px;
    object-fit: cover;
  }
}
</style>
